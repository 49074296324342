// import React from "react";

import axios from "axios";
import { URL_API } from "../config";

export const GetPrivilages = async () => {
  await axios
    .get(URL_API + "/api/getAuthorisation", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      },
    })
    .then((response) => {
      if (response.data.code === 100) {
        localStorage.setItem("segalivirp", response.data.data["current_token"]);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
