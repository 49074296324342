import axios from "axios";
import { URL_API } from "../config";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    var payload = {
      passwd: credentials.passwd,
    };
    axios
      .post(URL_API + "/api/login", payload, {
        headers: {
          Authorization: "Bearer " + credentials.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.headers);
          //resolve([200, { message: "Success" }]);
        }
        reject([202, { message: "Please check your email and password" }]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  var payload = {
    email: credentials.email,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(URL_API + "/api/resetpassword", payload, {
        headers: {
          Authorization: "Bearer " + credentials.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePassword(credentials) {
  var payload = {
    password: credentials.password,
    repeat_password: credentials.password2,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(URL_API + "/api/changepassword", payload, {
        headers: {
          Authorization: "Bearer " + credentials.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
