import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import en_EN from "./locales/en_EN.json";
import pl_PL from "./locales/pl_PL.json";
import es_ES from "./locales/es_ES.json";
import ca_CA from "./locales/ca_CA.json";
import tr_TR from "./locales/tr_TR.json";
import { DEFAULT_LANG } from "./config";

const fallbackLng = [DEFAULT_LANG];
const availableLanguages = ["es", "ca", "en", "pl", "tr"];

const resources = {
  es: {
    translation: es_ES,
  },
  ca: {
    translation: ca_CA,
  },
  en: {
    translation: en_EN,
  },
  pl: {
    translation: pl_PL,
  },
  tr: {
    translation: tr_TR,
  },
};

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is polish.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
