// import React, { useState, useEffect } from "react";
import * as types from "../../constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
  changePassword as authChangePassword,
} from "../../services/authService";
import { GetPrivilages } from "../../routes/GetPrivilages";

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          user_token: response.pragma,
        });
        localStorage.setItem("user_token", response.pragma);
        GetPrivilages();
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
    localStorage.removeItem("user_token");
    localStorage.removeItem("segalivirp");
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function changePassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CHANGE_PASSWORD_REQUEST });

    return authChangePassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_CHANGE_PASSWORD_SUCCESS,
          password: response.password,
          repeat_password: response.password2,
          token: response.token,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_CHANGE_PASSWORD_FAILURE });
        throw error;
      });
  };
}
