import React from "react";
import styled from "styled-components/macro";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { DEFAULT_LANG } from "../config";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const Image = styled.img`
  max-width: 30px;
  height: auto;
  margin-right: 5px;
`;

const languageMap = {
  es: { label: "Español", dir: "ltr", active: false, code: "es" },
  ca: { label: "Català", dir: "ltr", active: false, code: "cat" },
  en: { label: "English", dir: "ltr", active: false, code: "us" },
  pl: { label: "Polish", dir: "ltr", active: true, code: "pl" },
  tr: { label: "Turkish", dir: "ltr", active: true, code: "tr" },
};

const LanguageSelect = () => {
  const selected = localStorage.getItem("i18nextLng") || DEFAULT_LANG;
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <>
      <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
        <Image
          src={`/static/img/flags/${languageMap[selected].code}.png`}
        ></Image>{" "}
        {languageMap[selected].label}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List>
            <ListSubheader>{t("select_language")}</ListSubheader>
            {Object.keys(languageMap)?.map((item) => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                }}
              >
                <Image
                  src={`/static/img/flags/${languageMap[item].code}.png`}
                />
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </>
  );
};

export default LanguageSelect;
